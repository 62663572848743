._space3d {
  perspective: 3000;
  text-align: center;
  display: inline-block;
  padding: unset;
  border: unset;
  background-color: transparent;
  outline: unset;
  box-shadow: unset;
}
._3dbox {
    display: inline-block;
    transition: all 0.85s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform: rotateX(-90deg) rotateY(0deg) rotateZ(0deg);
}
._3dface {
    /* overflow: hidden; */
    position: absolute;
    opacity: 1;
    background-size: auto 100%;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.defaultFace {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 12px;
    box-shadow: inset 0 0 6px #000000;
    background-color: #fff;
}
.defaultFace div {
    display: flex;
    align-items: center;
    justify-content: center;
}
.defaultFace span {
    width: 60%;
    height: 60%;
    box-shadow: 0 0 6px #000000;
    border-radius: 50%;
    background-color: #000;
}
._space3d.one ._3dbox {
    transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
}
._space3d.two ._3dbox {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
._space3d.three ._3dbox {
    transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
}
._space3d.four ._3dbox {
    transform: rotateX(-90deg) rotateY(90deg) rotateZ(90deg);
}
._space3d.five ._3dbox {
    transform: rotateX(90deg) rotateY(-90deg) rotateZ(90deg);
}
._space3d.rolling ._3dbox {
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    animation: spin 2s infinite linear;
}

@supports (-moz-appearance:none) {
    ._space3d.rolling ._3dbox, ._3dbox  {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
    }
}

@keyframes spin {
  0% { transform: translateZ(-100px) rotateX(0deg) rotateY(0deg) rotateZ(0deg); }
  16% { transform: translateZ(-100px) rotateX(180deg) rotateY(180deg) rotateZ(0deg); }
  33% { transform: translateZ(-100px) rotateX(360deg) rotateY(90deg) rotateZ(180deg); }
  50% { transform: translateZ(-100px) rotateX(360deg) rotateY(360deg) rotateZ(360deg); }
  66% { transform: translateZ(-100px) rotateX(180deg) rotateY(360deg) rotateZ(270deg); }
  83% { transform: translateZ(-100px) rotateX(270deg) rotateY(180deg) rotateZ(180deg); }
  100% { transform: translateZ(-100px) rotateX(360deg) rotateY(360deg) rotateZ(360deg); }
}