body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'),
    url('./fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Luckiest Guy';
  src: local('LuckiestGuy'),
    url('./fonts/LuckiestGuy/LuckiestGuy-Regular.ttf') format('truetype');
}